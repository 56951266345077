<template>
  <v-menu offset-y min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          outlined
          color="primary"
          v-bind="attrs"
          v-on="on"
          :loading="isProcessing"
          :disabled="isProcessing"
      >
        <v-icon left>mdi-download</v-icon>
        {{ t('export') }}
        <v-icon right>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
          v-for="(item, index) in exporters"
          :key="index"
          @click="startExport(item.action)"
          :disabled="isProcessing"
          :class="{ 'with-divider': index < 3 }"
      >
        <v-list-item-content>
          <v-list-item-title>{{ item.label }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>

export default {
  name: "ExportButton",
  emits: ["export"],
  props: {
    isProcessing: Boolean,
    exporters: Array,
  },
  methods: {
    t: function (key) {
      return this.$t('components.export-button.' + key)
    },
    startExport(action) {
      this.$emit('export', action);
    }
  }
}
</script>

<style lang="scss" scoped>
.v-list-item {
  padding: 0 14px !important
}

.with-divider {
  border-bottom: 0.5px solid #EBEDF2;
}
</style>